import styled from 'styled-components';
import {
  Tab as UnstyledTab,
  TabList as UnstyledTabList,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel,
} from 'react-tabs';

const Tabs = styled(UnstyledTabs)`
  background: #ffffff;
`;

const TabList = styled(UnstyledTabList)`
  border-bottom: solid 1px #eceff0;
  padding: 0;
  margin: 0;
`;

const Tab = styled(UnstyledTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled',
})`
  display: inline-flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0.625rem 1rem;
  color: #888888;
  cursor: pointer;

  &.selected {
    color: #10abe2;
    border-bottom: solid 3px #cecece;
    font-weight: bold;
  }

  &.disabled {
    color: #eceff0;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(UnstyledTabPanel).attrs({ selectedClassName: 'selected' })`
  display: none;
  padding: 0.625rem 0;

  &.selected {
    display: block;
  }
`;

Tab.tabsRole = 'Tab';
Tabs.tabsRole = 'Tabs';
TabPanel.tabsRole = 'TabPanel';
TabList.tabsRole = 'TabList';

export { Tab, TabList, Tabs, TabPanel };
