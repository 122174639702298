import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import ReactGA from 'react-ga';
import { FiYoutube, FiTrello } from 'react-icons/fi';
import moment from 'moment';

import Spinner from '../Spinner';
import { ACTION, CATEGORY } from '../../constants/ga';

const spinnerSize = '4.5rem';

function getHeight(aspectRatio) {
  const [width, height] = aspectRatio.split(':');

  return (100 * height) / width;
}

const mutedText = css`
  font-size: 0.9375rem;
  color: #7e7e7e;
`;

const Wrapper = styled.div`
  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      display: flex;
      margin-bottom: 1rem;
    `}
`;

const flashingAnimation = keyframes`
  0% {
    color: #3c9ccb;
    transform: scale(.9, .9);
    transform: scale3d(.9, .9, .9);
  }
  100% {
    color: #ff53c9;
    transform: scale(1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
`;

const NewBadge = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  width: 50px;
  height: 25px;
  text-align: center;
  background: white;
  padding: 0 8px;
  border-radius: 4px;

  span {
    font-weight: bold;
    display: inline-block;
    font-size: 1rem !important;
    animation: ${flashingAnimation} 1s linear infinite;
    animation-direction: alternate;
  }
`;

const Thumbnail = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-top: ${props => getHeight(props.aspectRatio)}%; // height by aspect ratio
  border-radius: 3px;
  background: #000000;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    margin: 0;
    vertical-align: middle;
    border-radius: 2px;
    opacity: 0.98;
  }

  ${Spinner} {
    position: absolute;
    top: calc(50% - ${spinnerSize} / 2);
    left: calc(50% - ${spinnerSize} / 2);
  }

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      flex: 1;
      align-self: baseline;
      margin-right: 1rem;
      padding-top: ${props => getHeight(props.aspectRatio) / 2}%;
    `}
`;

const ThumbnailLink = ({ aspectRatio, ...props }) => <Link {...props} />;

ThumbnailLink.propTypes = {
  aspectRatio: PropTypes.string,
};

ThumbnailLink.defaultProps = {
  aspectRatio: '16:9',
};

const Time = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #373933;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  color: #ffffff;
  font-size: 0.875rem;
  padding: 6px 9px;
  line-height: 0.875rem;
  font-weight: 500;
`;

const Title = styled.div`
  color: #343434;
  font-weight: 500;
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      white-space: normal;
    `}

  a {
    color: #2e2e2e;
  }
`;

const Subtitle = styled.div`
  ${mutedText}
`;

const ViewCount = styled.div`
  ${mutedText}
`;

const MoreInfo = styled.div`
  margin-top: 15px;

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      flex: 1;
      margin-top: 0;
      overflow: hidden;
    `}
`;

const AbbrTitle = styled.abbr`
  border: none !important;
`;

const GeoGebraIcon = styled(FiTrello)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

const VideoIcon = styled(FiYoutube)`
  margin-right: 0.5rem;
  vertical-align: text-bottom;
`;

function Video({
  title,
  subtitle,
  thumbnail,
  link,
  duration,
  viewCount,
  layout,
  aspectRatio,
  thumbnailOnly,
  loading,
  gaAction,
  gaLabel,
  publishDate,
  type,
  ...props
}) {
  const ga = () => {
    ReactGA.event({
      category: CATEGORY.VIDEO,
      action: gaAction || ACTION.OPEN_VIDEO,
      label: gaLabel,
    });
  };

  const mPublishDate = moment(publishDate);
  const isNew = mPublishDate.isValid() && mPublishDate.isAfter(moment().add(-1, 'M'));

  return (
    <Wrapper layout={layout} {...props} onClick={ga}>
      <Thumbnail
        as={link ? ThumbnailLink : 'div'}
        to={link}
        layout={layout}
        aspectRatio={aspectRatio}
      >
        {thumbnail && <img src={thumbnail} alt="Video Thumbnail" />}
        {duration && <Time>{duration}</Time>}
        {loading && <Spinner color="#10abe2" size={spinnerSize} />}
        {isNew && (
          <NewBadge>
            <span>Mới</span>
          </NewBadge>
        )}
      </Thumbnail>
      {!thumbnailOnly && (
        <MoreInfo layout={layout}>
          <Title layout={layout}>
            {type === 'geogebra' ? <GeoGebraIcon /> : <VideoIcon />}
            <AbbrTitle title={title}>
              <Link to={link}>{title}</Link>
            </AbbrTitle>
          </Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {viewCount != null && <ViewCount>{`${viewCount} lượt xem`}</ViewCount>}
        </MoreInfo>
      )}
    </Wrapper>
  );
}

Video.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.string,
  link: PropTypes.string,
  duration: PropTypes.string,
  viewCount: PropTypes.number,
  layout: PropTypes.oneOf(['default', 'vertical']),
  aspectRatio: PropTypes.string,
  thumbnailOnly: PropTypes.bool,
  loading: PropTypes.bool,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  publishDate: PropTypes.string,
  type: PropTypes.oneOf(['video', 'geogebra']),
};

Video.defaultProps = {
  title: '',
  subtitle: '',
  thumbnail: '',
  link: '',
  duration: '',
  viewCount: 0,
  layout: 'default',
  aspectRatio: '16:9',
  thumbnailOnly: false,
  loading: false,
  gaAction: '',
  gaLabel: '',
  publishDate: null,
  type: 'video',
};

export default Video;

export const query = graphql`
  fragment VideoFragment on TrangVideo {
    rawID
    code
    name
    description {
      md
    }
    thumbnail
    duration
    copyright
    publishDate
    genre
    language
    version
    format
    sharingPermission
    views
    subject {
      name
      code
    }
    level
    tags
    slug
    demoViews
    contentType
    isUnpublished
  }
`;
