import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import _ from 'lodash';
import moment from 'moment';

import * as API from '../apis';
import { LEVELS } from '../constants/levels';
import { slugify } from '../utils/slug';
import { ACTION, CATEGORY, LABEL } from '../constants/ga';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import FullWidth from '../components/FullWidth';
import BannerImage from '../components/BannerImage';
import Video from '../components/Video';
import VideoPlaceholder from '../components/Placeholder/Video';
import PromoList from '../components/PromoList';
import MutedText from '../components/MutedText';
import BannerVideo from '../components/BannerVideo';
import { Tab, TabList, TabPanel, Tabs } from '../components/Tabs';
import Button from '../components/Button';
import BlogList from '../components/BlogList';

const Section = styled.section`
  border-top: solid ${({ top }) => (top ? '0' : '1px')} #eceff0;
  margin-bottom: 0px;
`;

const SectionTitle = styled.h3`
  margin: 1rem 0 0.125rem;
  font-weight: 600;
  color: #10abe2;

  ${({ top }) => top && `margin-top: 0`}
`;

const SectionSubTitle = styled.h2`
  margin: 1.25rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #10abe2;
  float: right;

  ${({ top }) => top && `margin-top: 0`}
`;

const VideoList = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  margin-top: 0.75rem;
  margin-bottom: 1.875rem;
`;

const ViewMore = styled(SectionTitle)`
  text-align: center;
  display: block;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 20px 0;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;

const VideoFilterButton = styled(Button)`
  margin-right: 20px;
  margin-bottom: 10px;
  ${props =>
    !props.isActive &&
    `
    background: #eceff0;
    color: #637076;

    :hover {
      color: #fff;
    }
  `}
`;

const FilterVideoField = styled.span`
  width: 100px;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 17px;
  color: #10abe2;
`;

const NoVideo = styled(MutedText)`
  font-size: 1rem;
  display: inline-block;
  font-weight: normal;
`;

const VideoTabPanel = styled(TabPanel)`
  padding: 0.25rem 0 0;
`;

const VIDEO_LIMIT = 16;

function VideoTab({
  levels,
  subjects,
  setCurrentSubject,
  currentSubject,
  setCurrentLevel,
  currentLevel,
  videosLoading,
  videos,
  nextPageVideos,
  fetchVideos,
  levelsSubjectHasVideos,
}) {
  const levelsHasVideo = useMemo(() => {
    return _.filter(levels, level => !!levelsSubjectHasVideos[level]);
  }, [levels, levelsSubjectHasVideos]);

  const subjectHasVideo = useMemo(() => {
    return _.filter(
      subjects,
      subject => _.indexOf(levelsSubjectHasVideos[currentLevel], subject.code) > -1
    );
  }, [currentLevel, levelsSubjectHasVideos]);

  if (!levelsHasVideo || levelsHasVideo.length <= 0) {
    return <></>;
  }

  return (
    <>
      <FilterVideoField style={{ marginTop: 20 }}>Lớp học:</FilterVideoField>
      {_.map(levelsHasVideo, level => (
        <VideoFilterButton
          size="small"
          key={level}
          onClick={() => setCurrentLevel(level)}
          isActive={currentLevel === level}
          disabled={videosLoading}
        >
          {`Lớp ${level}`}
        </VideoFilterButton>
      ))}
      {!currentLevel && <NoVideo>Vui lòng chọn lớp học.</NoVideo>}
      <Section top>
        {subjectHasVideo && subjectHasVideo.length > 0 && (
          <SectionTitle>
            <FilterVideoField>Môn học:</FilterVideoField>
            {subjectHasVideo &&
              _.map(subjectHasVideo, subject => (
                <VideoFilterButton
                  size="small"
                  key={subject.code}
                  onClick={() => setCurrentSubject(subject)}
                  isActive={subject === currentSubject}
                  disabled={videosLoading}
                >
                  {subject.name}
                </VideoFilterButton>
              ))}
            {currentLevel && !currentSubject && <NoVideo>Vui lòng chọn môn học.</NoVideo>}
          </SectionTitle>
        )}
        <VideoList>
          {currentLevel &&
            currentSubject &&
            videos &&
            videos.map(video => (
              <Video
                key={video.code}
                title={video.name}
                subtitle={video.subject && video.subject.name}
                thumbnail={video.thumbnail}
                link={`videos/${slugify(video.name)}`}
                duration={video.duration}
                viewCount={null}
                publishDate={video.publishDate}
                type={video.contentType}
                gaLabel={LABEL.HOME_PAGE_SUBJECT_VIDEOS}
              />
            ))}
          {currentLevel && currentSubject && videosLoading && (
            <>
              <VideoPlaceholder />
              <VideoPlaceholder />
              <VideoPlaceholder />
              <VideoPlaceholder />
            </>
          )}
        </VideoList>
        {!videosLoading && nextPageVideos !== -1 && (
          <>
            <ViewMore onClick={() => fetchVideos(currentSubject, currentLevel, nextPageVideos)}>
              Xem thêm
            </ViewMore>
          </>
        )}
      </Section>
    </>
  );
}

VideoTab.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.number),
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentSubject: PropTypes.func,
  currentSubject: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentLevel: PropTypes.func,
  currentLevel: PropTypes.number,
  videosLoading: PropTypes.bool,
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  levelsSubjectHasVideos: PropTypes.arrayOf(PropTypes.shape({})),
  nextPageVideos: PropTypes.number,
  fetchVideos: PropTypes.func,
};

VideoTab.defaultProps = {
  levels: [],
  subjects: [],
  setCurrentSubject: () => {},
  currentSubject: null,
  setCurrentLevel: () => {},
  currentLevel: null,
  videosLoading: null,
  videos: null,
  levelsSubjectHasVideos: {},
  nextPageVideos: -1,
  fetchVideos: () => {},
};

function IndexPage({ data }) {
  const { newReleasedNodes, allTrangPromoNodes, allTrangBlogNodes } = data;
  const newReleaseVideos = newReleasedNodes.edges.map(({ node }) => node);
  const allTrangPromo = allTrangPromoNodes.edges.map(({ node }) => node);
  const allTrangBlog = allTrangBlogNodes.edges.map(({ node }) => node);
  const [subjects, setSubjects] = useState([]);
  const [levelsSubjectHasVideos, setLevelsSubjectHasVideos] = useState({});
  const [currentSubject, setCurrentSubject] = useState();
  const [videos, setVideos] = useState();
  const [nextPageVideos, setNextPageVideos] = useState();
  const [currentLevel, setCurrentLevel] = useState();
  const [videosLoading, setVideosLoading] = useState(false);

  const fetchVideos = async (
    subject = currentSubject,
    level = currentLevel,
    nextPage = nextPageVideos || 1,
    resetVideos = false
  ) => {
    if (!subject || !level) {
      setVideos([]);
      setNextPageVideos(-1);
      return;
    }
    if (videosLoading) {
      return;
    }
    try {
      if (resetVideos) {
        setVideos([]);
        setNextPageVideos(-1);
      }
      setVideosLoading(true);
      const response = await API.getVideosBySubject({
        subject: subject.code,
        level,
        page: nextPage,
        limit: VIDEO_LIMIT,
      });
      setVideosLoading(false);
      setVideos(videosState => (videosState ? videosState.concat(response.data) : response.data));
      setNextPageVideos(response.hasNextPages ? nextPage + 1 : -1);
    } catch (error) {
      setVideosLoading(false);
      console.error(error);
    }

    if (currentLevel) {
      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.FILTER_BY_LEVEL,
        label: LABEL.HOME_PAGE,
        value: level,
      });
    } else if (nextPage > 1) {
      ReactGA.event({
        category: CATEGORY.VIDEO,
        action: ACTION.VIEW_MORE_BY_SUBJECT,
        label: LABEL.HOME_PAGE,
        value: subject,
      });
    }
  };

  useEffect(() => {
    async function fetchSubjects() {
      try {
        const topLevelSubjectsResponse = await API.getTopLevelSubjects();
        const levelsSubjectHasVideosResponse = await API.getLevelsSubjectsHasVideos();
        setSubjects(topLevelSubjectsResponse.subjects);
        setLevelsSubjectHasVideos(levelsSubjectHasVideosResponse);
      } catch (error) {
        console.error(error);
      }
    }

    fetchSubjects();
  }, []);

  useEffect(() => {
    setCurrentSubject(null);
    setVideos([]);
  }, [currentLevel]);

  useEffect(() => {
    fetchVideos(currentSubject, currentLevel, 1, true);
  }, [currentSubject, currentLevel]);

  const promos = _.filter(
    allTrangPromo,
    promo =>
      promo.pinnedToHomepage &&
      moment(promo.validFrom).isSameOrBefore(moment()) &&
      moment(promo.validUntil).isSameOrAfter(moment())
  );

  return (
    <Layout noTopPadding>
      <SEO title="Trang chủ" />

      <FullWidth>
        <BannerVideo />
      </FullWidth>

      {promos.length > 0 && (
        <Section top>
          <SectionTitle>
            Khuyến mãi
            <SectionSubTitle top>
              <a href="/promos">Xem tất cả khuyến mãi</a>
            </SectionSubTitle>
          </SectionTitle>
          <PromoList promos={promos} />
          <br />
        </Section>
      )}

      <Section top>
        <SectionTitle>Danh sách các video</SectionTitle>
        <Tabs defaultIndex={0} onSelect={() => setCurrentLevel(null)}>
          <TabList>
            <Tab disabled={videosLoading}>Cấp 1</Tab>
            <Tab disabled={videosLoading}>Cấp 2</Tab>
            <Tab disabled={videosLoading}>Cấp 3</Tab>
          </TabList>

          <VideoTabPanel>
            <VideoTab
              levels={LEVELS.slice(0, 5)}
              subjects={subjects}
              setCurrentSubject={setCurrentSubject}
              currentSubject={currentSubject}
              setCurrentLevel={setCurrentLevel}
              currentLevel={currentLevel}
              videosLoading={videosLoading}
              videos={videos}
              nextPageVideos={nextPageVideos}
              fetchVideos={fetchVideos}
              levelsSubjectHasVideos={levelsSubjectHasVideos}
            />
          </VideoTabPanel>
          <VideoTabPanel>
            <VideoTab
              levels={LEVELS.slice(5, 9)}
              subjects={subjects}
              setCurrentSubject={setCurrentSubject}
              currentSubject={currentSubject}
              setCurrentLevel={setCurrentLevel}
              currentLevel={currentLevel}
              videosLoading={videosLoading}
              videos={videos}
              nextPageVideos={nextPageVideos}
              fetchVideos={fetchVideos}
              levelsSubjectHasVideos={levelsSubjectHasVideos}
            />
          </VideoTabPanel>
          <VideoTabPanel>
            <VideoTab
              levels={LEVELS.slice(9, 12)}
              subjects={subjects}
              setCurrentSubject={setCurrentSubject}
              currentSubject={currentSubject}
              setCurrentLevel={setCurrentLevel}
              currentLevel={currentLevel}
              videosLoading={videosLoading}
              videos={videos}
              nextPageVideos={nextPageVideos}
              fetchVideos={fetchVideos}
              levelsSubjectHasVideos={levelsSubjectHasVideos}
            />
          </VideoTabPanel>
        </Tabs>
      </Section>

      <Section top>
        <SectionTitle>Mới phát hành</SectionTitle>
        <VideoList>
          {newReleaseVideos.map(video => (
            <Video
              key={video.slug}
              title={video.name}
              subtitle={video.subject && video.subject.name}
              thumbnail={video.thumbnail}
              link={`videos/${video.slug}`}
              duration={video.duration}
              viewCount={null}
              publishDate={video.publishDate}
              type={video.contentType}
            />
          ))}
        </VideoList>
      </Section>

      <FullWidth>
        <BannerImage />
      </FullWidth>

      {allTrangBlog.length > 0 && (
        <Section top>
          <SectionTitle>
            Bài viết
            <SectionSubTitle top>
              <a href="/blogs">Xem tất cả bài viết</a>
            </SectionSubTitle>
          </SectionTitle>
          <BlogList blogs={allTrangBlog} />
          <br />
        </Section>
      )}
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    newReleasedNodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allTrangPromoNodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allTrangBlogNodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    newReleasedNodes: allTrangVideo(
      filter: { isUnpublished: { eq: false } }
      sort: { order: DESC, fields: publishDate }
      limit: 8
    ) {
      edges {
        node {
          ...VideoFragment
        }
      }
    }
    allTrangPromoNodes: allTrangPromo {
      edges {
        node {
          slug
          code
          title
          appliedFor
          state
          subscription {
            name
            code
            pricing12month
            pricing3month
            pricing5month
          }
          content {
            brief
            extended
          }
          pinnedToHomepage
          validFrom
          validUntil
          pricing12month
          pricing3month
          pricing5month
        }
      }
    }
    allTrangBlogNodes: allTrangBlog(sort: { order: DESC, fields: publishedDate }, limit: 5) {
      edges {
        node {
          slug
          title
          state
          updatedAt
          updatedBy {
            email
          }
          publishedDate
          createdAt
          createdBy {
            email
          }
          content {
            brief
            extended
          }
        }
      }
    }
  }
`;
