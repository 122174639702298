import React from 'react';
import { Link } from 'gatsby';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import bannerVideoMp4 from '../videos/banner.mp4';

const BackgroundWrapper = styled.div`
  overflow: hidden;
  background: #10abe2;
  width: 100vw;
  padding: 0;

  @media (max-width: 1440px) {
    padding: 1rem 0;
  }

  @media (max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: 750px) {
    padding: 2rem 0;
  }
`;

const Video = styled.video`
  width: 100%;
  max-width: 750px;
  flex: 1;
  height: auto !important;
  z-index: 1;

  @media (max-width: 600px) {
    height: 100% !important;
    width: auto !important;
  }

  @media (max-width: 1240px) {
    width: 400px !important;
  }

  @media (max-width: 750px) {
    width: 100% !important;
    height: auto;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  z-index: 1;

  @media (max-width: 1240px) {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  height: 500px;
  display: flex;
  flex-direction: row;
  padding: 0 1rem;

  @media (max-width: 1440px) {
    height: 350px;
    width: 100%;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: 2.75rem;
  font-weight: normal;
  margin: 0;

  @media (max-width: 1440px) {
    font-size: 2.25rem;
  }

  @media (max-width: 1000px) {
    font-size: 1.65rem;
    margin-bottom: 0.5rem;
  }
`;

const BigTitle = styled.h1`
  font-size: 5.5rem;
  font-weight: normal;

  @media (max-width: 1440px) {
    font-size: 4rem;
  }

  @media (max-width: 1000px) {
    font-size: 3rem;
    margin-bottom: 1.25rem;
  }
`;
const SubTitle = styled.h3`
  margin-bottom: 0.5rem;
  font-weight: normal;

  @media (max-width: 1000px) {
    font-size: 1rem;
  }
`;

const linkButton = css`
  display: inline-block;
  padding: 0 1rem;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
`;

const PurchaseLink = styled(Link).attrs({ to: '/purchase-subscription/' })`
  ${linkButton};
  background: #10abe2;
  margin: 0 0.375rem 0 1rem;
  color: #ffffff;
  font-size: 0.875rem;

  &:hover {
    background: ${darken(0.04, '#10abe2')};
    color: #ffffff;
  }
`;

const AboutLink = styled(Link).attrs({ to: '/about/' })`
  ${linkButton};
  color: #10abe2;
  background: white;
  font-size: 0.875rem;

  &:hover {
    border-color: ${darken(0.04, '#b7b5bd')};
    color: ${darken(0.1, '#78757a')};
  }
`;

const VideoWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const VideoGlow = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
`;

function BannerVideo() {
  return (
    <BackgroundWrapper>
      <Wrapper>
        <TitleWrapper>
          <Title>Ngân hàng video giáo dục</Title>
          <BigTitle>Trạng</BigTitle>
          <SubTitle>Video trực quan và tương tác</SubTitle>
          <SubTitle>Hỗ trợ giảng dạy và học tập nhanh!</SubTitle>
          <div>
            <br />
            <AboutLink>Giới thiệu</AboutLink>
            <PurchaseLink>Xem gói cước</PurchaseLink>
          </div>
        </TitleWrapper>
        <VideoWrapper>
          <Video autoPlay muted loop playsInline>
            <source src={bannerVideoMp4} type="video/mp4" />
          </Video>
          <VideoGlow />
        </VideoWrapper>
      </Wrapper>
    </BackgroundWrapper>
  );
}

export default BannerVideo;
