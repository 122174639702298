import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

const Container = styled.div`
  cursor: pointer;
  clear: both;
  color: #2e2e2e;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
`;
const Bullet = styled.span`
  margin-right: 10px;
`;

const PublishDate = styled.div`
  display: inline-block;
  color: gray;
  margin-left: 5px;
`;

const Title = styled.span`
  margin-right: 5px;
`;

const BlogList = ({ blogs }) => {
  return (
    <>
      {_.map(blogs, blog => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={`/blogs/${blog.slug}`} target="_blank">
          <Container>
            <Bullet>•</Bullet>
            <Title>{blog.title}</Title>
            <PublishDate>{`(${moment(blog.publishedDate).format('DD/MM/YYYY')})`}</PublishDate>
            <br />
          </Container>
        </a>
      ))}
    </>
  );
};

BlogList.propTypes = {
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

BlogList.defaultProps = {
  blogs: [],
};

export default BlogList;
