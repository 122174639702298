import styled from 'styled-components';

const FullWidth = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
`;

export default FullWidth;
