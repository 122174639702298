import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

const Wrapper = styled.div`
  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      display: flex;
      margin-bottom: 1rem;
    `}
`;

const Left = styled(RectShape)`
  padding-top: 56.25%;

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      flex: 1;
      margin-right: 1rem !important;
      padding-top: ${56.25 / 2}%;
    `}
`;

const Right = styled(TextBlock)`
  margin-top: 25px;

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      flex: 1;
      margin-top: 0;
    `}
`;

function VideoPlaceholder({ layout, ...props }) {
  return (
    <Wrapper layout={layout} {...props}>
      <Left layout={layout} color="#e0e0e0" />
      <Right layout={layout} rows={4} color="#e0e0e0" />
    </Wrapper>
  );
}

VideoPlaceholder.propTypes = {
  layout: PropTypes.string,
};

VideoPlaceholder.defaultProps = {
  layout: 'default',
};

export default VideoPlaceholder;
